import React, { useState, useEffect } from "react";
import Typewriter from "typewriter-effect";
import "../styles/Card.css"; // Archivo CSS para los estilos
import InteractiveSquare from "./InteractiveSquare";
import { useNavigate } from "react-router-dom";


const AnimatedCard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLocked, setIsLocked] = useState(false); // Bloquea la interacción después del primer clic
    const [showMessage, setShowMessage] = useState(false);
    const [showButton, setShowButton] = useState(false);  
    const [isLastTyped, setIsLastTyped] = useState(false); // Controla si la última frase fue escrita
    const navigate = useNavigate();

  
    const handleClick = () => {
      if (isLocked) return; // No permite interactuar si está bloqueado
      setIsOpen(true); // Abre la tarjeta
      setIsLocked(true); // Bloquea después del primer clic
      setTimeout(() => {
        setShowButton(true);
      }, 14000);
    };
  
    // Mostrar mensaje una vez que la tarjeta esté completamente abierta
    useEffect(() => {
      if (isOpen) {
        const timer = setTimeout(() => {
          setShowMessage(true);
        }, 1000); // Tiempo suficiente para completar la animación de apertura
        return () => clearTimeout(timer);
      }
    }, [isOpen]);

    const handleButtonClick = () => {
      navigate("/inicio"); // Redirige a la página "Hola Mundo"
    };
  
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <div className={`card-wrapper ${isLocked ? "locked" : ""}`} onClick={handleClick}>
        <div className={`card ${isOpen ? "open" : ""}`}>
          <div className="card-half left"></div>
          <div className="card-half right"></div>
          <div className="card-content">
          {isOpen && (
            <div className={`typewriter-container ${isLastTyped ? "done" : ""}`}>
              {/* <Typewriter
                options={{
                  strings: [
                    "¡Gracias por acompañarnos!",
                    "Estamos muy felices de compartir este día especial contigo",
                    "Esperamos que lo disfrutes al máximo", // Última frase
                  ],
                  autoStart: true,
                  loop: false, // No repetir frases
                  deleteSpeed: null, // Velocidad de borrado (si aplica)
                  cursor: "|", // Cursor personalizado
                }}
                onStringTyped={(index, self) => {
                  console.log("terminando", index)
                  if (index === 2) {
                    // Si es la última frase
                    setIsLastTyped(true); // Marca como escrita
                    self.stop(); // Detén la animación
                  }
                }}
              /> */}
              <Typewriter
                options={{
                  delay: 50, // Reduce el delay entre caracteres para escribir más rápido
                  // deleteSpeed:30
                }}
                onInit={(typewriter) => {
                  typewriter.typeString('¡Gracias por acompañarnos!')
                    .callFunction(() => {
                      console.log('String typed out!');
                    })
                    .pauseFor(2500)
                    .deleteAll(4)
                    .typeString('Estamos muy felices de compartir este día especial contigo')
                    .pauseFor(2500)
                    .deleteAll(4)
                    .typeString('Esperamos que lo disfrutes al máximo')
                    .pauseFor(2500)
                    .deleteAll(4)
                    .typeString('15 | FEB | 2024')
                    .start();
                }}
              />
            </div>
          )}
           {showButton && (
              <button className="redirect-button" onClick={handleButtonClick}>
                Lista de Novios
              </button>
            )}
          {isOpen && (
        <InteractiveSquare x="16%" y="73%" link="https://maps.app.goo.gl/xdnh8vkRaEU2RBZf8" />
          )}
        </div>
        </div>
        </div>
      </div>
      
    );
  };
  
  export default AnimatedCard;