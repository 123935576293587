export const ar = 
  [{
    id:1,
    title: "Expedición al Cairo",
    description: "Un pasaje al Cairo para enfrentarse al villano DIO y liberar al mundo de su maldad.",
    image: "/assets/jojo.webp", // Agregar la imagen en la carpeta /public/assets
    price: "60.000",
    category: "Anime"
  }, {
    id:2,
    title: "Entrenamiento Ninja en la Aldea Oculta de Konoha",
    description: "Sorprende a los novios con una experiencia única donde se convertirán en ninjas y enfrentarán misiones como un verdadero equipo.",
    image: "/assets/konoha.webp", // Agregar la imagen en la carpeta /public/assets
    price: "95,000 CLP",
    category: "Anime"
  },
  {
    id:3,
    title: "Caza de Titanes en el Muro María",
    description: "Un obsequio lleno de adrenalina: únanse para derrotar a los titanes y proteger a la humanidad en una aventura inolvidable.",
    image: "/assets/muromaria.webp", // Agregar la imagen en la carpeta /public/assets
    price: "140,000 CLP",
    category: "Anime"
  },
  {
    id:4,
    title: "Viaje en el Tren Infinito",
    description: "Un regalo romántico y legendario: aborden el Tren Infinito y enfrenten juntos los desafíos mientras protegen a los pasajeros.",
    image: "/assets/image.webp", // Agregar la imagen en la carpeta /public/assets
    price: "110,000 CLP",
    category: "Anime"
  },
  {
    id:5,
    title: "Exploración Temporal en Akihabara",
    description: "Regala a los novios una travesía única a través del tiempo, descubriendo secretos y construyendo recuerdos en Akihabara.",
    image: "/assets/SteinsGate.jpg", // Agregar la imagen en la carpeta /public/assets
    price: "100,000 CLP",
    category: "Anime"
  },
  {
    id:6,
    title: "Viaje a Neo-Tokyo",
    description: "Un viaje a Neo tokio, lleno de acción en la metropolis más peligrosa del mundo",
    image: "/assets/akira.jpg", // Agregar la imagen en la carpeta /public/assets
    price: "80,000 CLP",
    category: "Anime"
  },
  {
    id:7,
    title: "Aventura en el Mundo Digital",
    description: "Transporta a los novios al Mundo Digital donde encontrarán a sus Digimon compañeros y vivirán una épica aventura en pareja.",
    image: "/assets/digital.jfif", // Agregar la imagen en la carpeta /public/assets
    price: "90,000 CLP",
    category: "Anime"
  },
  {
    id:8,
    title: "Partido en Vivo del Shohoku",
    description: "Regala a la pareja la oportunidad de asistir a un emocionante partido del equipo Shohoku, reviviendo la pasión y energía de 'Slam Dunk'.",
    image: "/assets/slamdunk.png", // Agregar la imagen en la carpeta /public/assets
    price: "80,000 CLP",
    category: "Anime"
  },
  {
    id:9,
    title: "Tour por la Estación Kamakurakōkōmae",
    description: "Una visita romántica al icónico cruce ferroviario de 'Slam Dunk', donde podrán capturar momentos inolvidables juntos.",
    image: "/assets/images.jfif", // Agregar la imagen en la carpeta /public/assets
    price: "50,000 CLP",
    category: "Anime"
  },
  {
    id:10,
    title: "Expedición a Vinland",
    description: "Invita a la pareja a una travesía hacia las tierras de Vinland, explorando los paisajes que inspiraron 'Vinland Saga'.",
    image: "/assets/vinland.webp", 
    price: "130,000 CLP",
    category: "Anime"
  },
  {
    id:11,
    title: "Plantar Arroz",
    description: "Ofrece una lección de humanidad e instrospección con la experiencia de ir a plantar arroz",
    image: "/assets/arroz.jpg_large", 
    price: "100,000 CLP",
    category: "Anime"
  },
  {
    id:12,
    title: "Pasaje para unirse a la banda del Halcón",
    description: "Una aventura épica para la pareja, permite que se unan a la banda del Halcón junto a su carismatico que lider que, por el momento, no ha hecho nada malo.",
    image: "/assets/banda.webp", 
    price: "160,000 CLP",
    category: "Anime"
  },
  
  {
    id: 13,
    title: "Juego de Vajilla Elegante",
    description: "Un hermoso juego de vajilla de porcelana para 12 personas, perfecto para cenas románticas o eventos especiales. Incluye platos, tazas, y cuencos decorados con un diseño clásico.",
    image: "/assets/vajilla.jpg",
    price: "80,000 CLP",
    category: "Hogar"
  },
  {
    id: 14,
    title: "Set de Toallas Premium",
    description: "Un set completo de toallas de algodón egipcio, suaves y absorbentes. Ideal para consentirse en pareja después de un relajante baño.",
    image: "/assets/toalla.webp",
    price: "25,000 CLP",
    category: "Hogar"
  },
  {
    id: 15,
    title: "Reloj de Pared Personalizado",
    description: "Un reloj de pared elegante con la posibilidad de personalizarlo con sus nombres y la fecha de la boda. ¡Un recuerdo especial para el hogar!",
    image: "/assets/reloj.avif",
    price: "40,000 CLP",
    category: "Hogar"
  },
  {
    id: 16,
    title: "Juego de Sartenes Antiadherentes",
    description: "Un set de sartenes de alta calidad con recubrimiento antiadherente. Perfecto para cocinar juntos sus platillos favoritos.",
    image: "/assets/sarten.avif",
    price: "35,000 CLP",
    category: "Hogar"
  },
  {
    id: 17,
    title: "Edredón Matrimonial de Lujo",
    description: "Un edredón acogedor y elegante, perfecto para mantenerlos cálidos en las noches frías. Disponible en colores neutros que combinan con cualquier decoración.",
    image: "/assets/edredon.jpg",
    price: "50,000 CLP",
    category: "Hogar"
  },
  {
    id: 18,
    title: "Escapada Romántica a París",
    description: "Un viaje inolvidable para dos a la ciudad del amor. Incluye alojamiento cerca de la Torre Eiffel y un paseo en barco por el Sena.",
    image: "/assets/paris.avif",
    price: "250,000 CLP",
    category: "Viajes"
  },
  {
    id: 19,
    title: "Luna de Miel en las Maldivas",
    description: "Un paquete de lujo que incluye estadía en un bungalow sobre el agua, actividades acuáticas y cenas románticas bajo las estrellas.",
    image: "/assets/maldivas.jpg",
    price: "300,000 CLP",
    category: "Viajes"
  },
  {
    id: 20,
    title: "Aventura en Patagonia Chilena",
    description: "Exploren juntos la belleza de Torres del Paine. Incluye trekking guiado, alojamiento en cabañas acogedoras y excursiones exclusivas.",
    image: "/assets/patagonia.webp",
    price: "100,000 CLP",
    category: "Viajes"
  },
  {
    id: 21,
    title: "Tour por los Viñedos de Napa Valley",
    description: "Un viaje para los amantes del vino. Incluye catas privadas, estadía en un resort boutique y paseos por los viñedos.",
    image: "/assets/napa.jpg",
    price: "800,000 CLP",
    category: "Viajes"
  },
  {
    id: 22,
    title: "Relax en las Termas de Pucón",
    description: "Un paquete de descanso en el corazón de la naturaleza chilena. Incluye acceso a termas, masajes y cenas románticas.",
    image: "/assets/pucon.jfif",
    price: "100,000 CLP",
    category: "Viajes"
  },
  {
    id: 23,
    title: "Vacaciones en la Luna",
    description: "Un exclusivo viaje para dos al satélite natural de la Tierra. Disfruten de paseos bajo la baja gravedad, vistas espectaculares de la Tierra y cenas en un domo lunar.",
    image: "/assets/luna.webp",
    price: "35,000 CLP",
    category: "Viajes"
  },
  {
    id: 24,
    title: "Crucero por la Vía Láctea",
    description: "Un romántico crucero espacial que los llevará a visitar los sistemas estelares más hermosos de la galaxia. Incluye observación de supernovas y habitaciones con vista al infinito.",
    image: "/assets/via.jpg",
    price: "30,000 CLP",
    category: "Viajes"
  },
  {
    id: 25,
    title: "Aventura en el Cinturón de Orión",
    description: "Exploren juntos los misterios de las estrellas de Orión. Incluye un tour guiado por nebulosas, alojamiento en cápsulas espaciales y recuerdos galácticos.",
    image: "/assets/orion.jfif",
    price: "40,000 CLP",
    category: "Viajes"
  },
  {
    id: 26,
    title: "Escape al Agujero Negro de Sagitario A*",
    description: "Un viaje extremo al centro de nuestra galaxia. Experiencia única en el horizonte de eventos con tecnología avanzada para el regreso seguro. Solo para parejas valientes.",
    image: "/assets/sagitario.webp",
    price: "500,000 CLP",
    category: "Viajes"
  },
  {
    id: 27,
    title: "Luna de Miel en la Galaxia de Andrómeda",
    description: "Una experiencia mágica en la galaxia vecina. Disfruten de vistas impresionantes, cielos llenos de estrellas y cenas románticas con vistas de colisiones cósmicas.",
    image: "/assets/andromeda.jfif",
    price: "25,000 CLP",
    category: "Viajes"
  }
   ,
  {
    id: 28,
    title: "Set de servicios",
    description: "set de 12 tenedores, cucharas y cuchillos",
    price: "30,000 CLP",
    category: "Hogar" ,
    image: "/assets/tenedor.jpg",
  }
]

  