
import React, { useState } from 'react';
import Header from './components/Header';
import GiftCard from './components/GiftCard';
import Footer from './components/Footer';
import AnimatedCard from './components/Card';
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";

import InteractiveSquare from './components/InteractiveSquare';
import ConfirmPage from './components/ConfirmPage';
import Ruleta from './components/Ruleta';
import './styles/styles.css';  // Agregar los estilos
import {ar} from "./data/gifts"


const HelloWorld = () => {
  const navigate = useNavigate();

  const [categoria, setCategoria]=useState([])

  const handleBack = () =>{
    setCategoria([])
  }

  const hanndlCat = (category) =>{
    const reg = ar.filter((gift)=> gift.category === category)
    setCategoria(reg)
    console.log("reg", category, reg)
  }

  return (
    <div>
      <Header />
      {categoria?.length===0 ?
      <div
        style={{
          display: "grid",
          justifyContent: "center",
          padding: "20px",
          margin: "10px"
        }}>
          <h2 style={{
            color: "#324d21",
            fontFamily:"PT sans"
          }}> Selecciona una categoria</h2>
          
          <button onClick={()=>hanndlCat("Viajes")} style={{margin: "10px"}}>Viajes</button>
          <button onClick={()=>hanndlCat("Hogar")} style={{margin: "10px"}}>Hogar</button>
          <button onClick={()=>navigate(`/ruleta`)} style={{margin: "10px"}}>Ruleta</button>
          <button onClick={()=>hanndlCat("Anime")} style={{margin: "10px"}}>Anime</button>
        </div>

       : 
       <div style={{
        margin: "10px",
        alignContent: "center",
        justifyContent: "center"
       }}>
      <button onClick={handleBack}> {"volver a categorias"}</button>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gap: "20px",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        
        
      {categoria.map((regalo, index) => (
          <GiftCard
            key={regalo.id}
            id={regalo.id}
            title={regalo.title}
            description={regalo.description}
            image={regalo.image}
            price={regalo.price}
          />
        ))}
      </div>
      </div>
      }
      <Footer />
    </div>
  );
};



const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AnimatedCard />} />
        <Route path="/inicio" element={<HelloWorld />} />
        <Route path="/confirmar/:gift" element={<ConfirmPage />} />
        <Route path="/ruleta" element={<Ruleta />} />

      </Routes>
    </Router>
    // <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
    //     <AnimatedCard />
    //     {/* <InteractiveSquare x="200px" y="300px" link="https://google.com" /> */}
    // </div>
      
    // <div>
    //   <Header />
      
    //   <div className="gift-container">
    //     {regalos.map((regalo, index) => (
    //       <GiftCard
    //         key={index}
    //         title={regalo.title}
    //         description={regalo.description}
    //         image={regalo.image}
    //         price={regalo.price}
    //       />
    //     ))}
    //   </div>
    //   <Footer />
    // </div>
  );
};

export default App;
