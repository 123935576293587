import React from 'react';
import { useNavigate } from "react-router-dom";

const GiftCard = ({id, title, description, image, price }) => {

  const navigate = useNavigate();

  const handleSelect = () => {
    navigate(`/confirmar/${encodeURIComponent(id)}`); // Redirige con el título como parámetro
  };

  return (
    <div className="gift-card">
      <img
        // className="gift-card-ribbon"
        style={{
          position:"absolute",
          width:"30%",
          top:"0px",
          left:"10",
          height: "auto",
          border:"1px "
        }}
        src="/cinta.webp" // Cambia a la URL de tu cinta
        alt="Cinta"
      />
      <img src={image} alt={title} />
      <h2>{title}</h2>
      <p>{description}</p>
      <p><strong>$ {price} </strong></p>
      <button onClick={handleSelect}>Seleccionar este regalo</button>
    </div>
  );
};

export default GiftCard;