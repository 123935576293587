import React from 'react';

const Header = () => {
  return (
    <header>
      <h1>Lista de Regalos</h1>
      <p>¡Ayúdanos a cumplir nuestros sueños con un regalo virtual único!</p>
        
        <img alt={"left"} style={{
          position: "absolute",
          top: "20px",
          right: "0",
          height: "180px",
          width: "auto", 
            }} src={"/cap1.png"}  
        />
        <img  alt={"right"} style={{
          position: "absolute",
          top: "20px",
          left: "0",
          height: "180px",
          width: "auto", 
            }} src={"/cap.png"}  
        />
      
    </header>
  );
};

export default Header;