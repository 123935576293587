
import React, { useState } from "react";

import { ReactComponent as MyIcon } from '../svg/bank.svg'
import { Wheel } from 'react-custom-roulette'
import "../styles/ruleta.css"

const data = [
    { option: '0', style: { backgroundColor: '#008000', textColor: '#FFFFFF' } },
    { option: '10k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '20k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '30k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '40k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '50k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '60k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '70k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '80k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '90k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '100k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '110k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '120k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '130k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '140k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '150k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '160k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '170k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '180k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '190k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '200k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '210k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '220k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '230k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '240k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '250k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '260k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '270k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '280k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '290k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '300k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '310k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '320k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '330k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '340k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } },
    { option: '350k', style: { backgroundColor: '#FF0000', textColor: '#FFFFFF' } },
    { option: '360k', style: { backgroundColor: '#000000', textColor: '#FFFFFF' } }
    
];

const backgroundColors = ['#FF0000', '#000000', '#008000']; // Colores de la ruleta
const textColors = ['#FFFFFF', '#FFFFFF', '#FFFFFF']; // Colores del texto
const outerBorderColor = '#ffcc00'; // Borde dorado exterior
const innerBorderColor = '#1e1e1e'; // Borde oscuro interior
const radiusLineColor = '#ffcc00'; // Líneas divisoras doradas
const fontFamily = 'Arial';
const fontSize = 18;
const fontWeight = 'bold';
const fontStyle = 'normal';
const outerBorderWidth = 10;
const innerBorderWidth = 5;
const radiusLineWidth = 2;
const spinDuration = 1.5; // Duración del giro en segundos
const textDistance = 85; // Distancia del texto al centro
// const backgroundColors = ['#ff8f43', '#70bbe0', '#0b3351', '#f9dd50'];
// const textColors = ['#0b3351'];
// const outerBorderColor = '#ffc600';
// const outerBorderWidth = 10;
// const innerBorderColor = '#ffc600';
// const innerBorderWidth = 0;
const innerRadius = 0;
// const radiusLineColor = '#ffc600';
// const radiusLineWidth = 2;
// const fontFamily = 'Nunito';
// const fontWeight = 'bold';
// const fontSize = 16;
// const fontStyle = 'normal';
// const textDistance = 85;
// const spinDuration = 0.5;




const Ruleta = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [name, setName] = useState('');

  const [isModalOpen, setIsOpenModal] = useState(false);
  const [isTranfer, setIsTranfer] = useState(false);
  const handleCloseModal = () =>{
    setIsOpenModal(false)
    setIsTranfer(false)

  }

  const activandoTranfer = () =>{
      setIsTranfer(true)
  }

  const handleSpinClick = () => {
    if (!mustSpin) {
      const clickSound = new Audio('/sounds/spin.mp3');
      clickSound.play();
      const newPrizeNumber = Math.floor(Math.random() * data.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  return (
    <div className="roulette-container">
      <h1>Ruleta</h1>
      <div className="wheel">   
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={data}
          backgroundColors={backgroundColors}
          textColors={textColors}
          fontFamily={fontFamily}
          fontSize={fontSize}
          fontWeight={fontWeight}
          fontStyle={fontStyle}
          outerBorderColor={outerBorderColor}
          outerBorderWidth={outerBorderWidth}
          innerRadius={innerRadius}
          innerBorderColor={innerBorderColor}
          innerBorderWidth={innerBorderWidth}
          radiusLineColor={radiusLineColor}
          radiusLineWidth={radiusLineWidth}
          spinDuration={spinDuration}
          startingOptionIndex={2}
          // perpendicularText
          textDistance={textDistance}
          onStopSpinning={() => {
            setMustSpin(false);
            setIsOpenModal(true)
          }}
        />
        <input
            type="text"
            placeholder="Primero ingresa tu nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              padding: "10px",
              fontSize: "16px",
              marginBottom: "20px",
              width: "80%",
              border: "1px solid #ddd",
              borderRadius: "5px",
            }}
          />
        <button disabled={name.length===0} className={'spin-button'} onClick={handleSpinClick}>
          SPIN
        </button>
        
    </div>

    {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "30px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              maxWidth: "400px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <h2 style={{ color: "#4b2e2e", marginBottom: "20px" }}>
              { !isTranfer && "Selecciona tu Método de Pago"}
            </h2>
            { isTranfer ? 
                <div className="bank-details">
                <h2 className="bank-title">Datos Bancarios</h2>
                <div className="bank-info">
                    <p><strong>Nombre:</strong> Gabriel Eduardo Sanhueza Díaz</p>
                    <p><strong>Rut:</strong> 18.781.022-1</p>
                    <p><strong>Banco:</strong> BCI</p>
                    <p><strong>Tipo de cuenta:</strong>  Corriente</p>
                    <p><strong>N° de cuenta:</strong> 89804546</p>
                    <p><strong>Correo:</strong> gabriel.sanhueza.d@gmail.com</p>
                    <p><strong>Monto:</strong> {prizeNumber}0.000 CLP</p>

                </div>
              </div>
            :<div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                <div className="square" onClick={activandoTranfer}>
                    <MyIcon className="icon" />
                    <h3 className="title">Tranferencia Bancaria</h3>
                </div>
                <div className="square">
                    <img className="iconw" src="/webpay.jpg"/>
                    
                </div>
            </div>}
            <button
              onClick={handleCloseModal}
              style={{
                marginTop: "20px",
                padding: "5px 10px",
                fontSize: "12px",
                backgroundColor: "#ddd",
                color: "#333",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ruleta;
