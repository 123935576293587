import React, { useState } from "react";
import { WebpayPlus } from 'transbank-sdk'; // ES6
// import mysql from "mysql2";
import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as MyIcon } from '../svg/bank.svg'

import { ar } from "../data/gifts";

const ConfirmPage = () => {
    const { gift } = useParams(); // Obtén el regalo seleccionado de la URL
    const [name, setName] = useState("");
    const [isModalOpen, setIsOpenModal] = useState(false);
    const [isTranfer, setIsTranfer] = useState(false);
    const navigate = useNavigate();
    const eleccion = ar.find(reg => reg.id=== parseInt(gift))

    const buyOrder = "O-41831"; 
    const sessionId = "S-26801"; 
    const amount = "1564" ; 
    const returnUrl = "as";    // Es necesario ejecutar dentro de una función async para utilizar await

    // const db = mysql.createConnection({
    //     host: "localhost",
    //     user: "miregalo_pascuero",
    //     password: "G3-?2ZYm#kEy",
    //     database: "miregalo_pagos",
    // });

 

    const handleCloseModal = () =>{
        setIsOpenModal(false)
        setIsTranfer(false)

    }

    const activandoTranfer = () =>{
        setIsTranfer(true)
    }
   
    const handleProceed = async() => {
      if (!name) {
        alert("Por favor, ingresa tu nombre para continuar.");
        return;
      }
    //   try {
    //     setIsOpenModal(true)
        
    try {
        setIsOpenModal(true)

        fetch("https://api.miregalodeboda.com/save", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nombre: name,
            monto: eleccion.price,
            producto: eleccion.title,
          }),
        });
  
        // const result = await response.json();
        // if (response.ok) {
        //   alert("Datos guardados exitosamente.");
        // } else {
        //   alert(`Error: ${result.error}`);
        // }
      } catch (error) {
        console.error("Error al guardar los datos:", error);
        // alert("Ocurrió un error al guardar los datos.");
      }
        // const query = "INSERT INTO pagos (nombre, monto, categoria) VALUES (?, ?, ?)";

        // db.query(query, [name, eleccion.price, eleccion.title], (err, results) => {
        //     if (err) {
        //     console.error("Error al guardar los datos:", err);
        //     alert("Ocurrió un error al guardar los datos.");
        //     return;
        //     }

        //     alert("Datos guardados exitosamente.");
        //     console.log("Resultado:", results);
        // });
        // const createResponse = await (new WebpayPlus.Transaction()).create(
        //     buyOrder, 
        //     sessionId, 
        //     amount, 
        //     returnUrl
        // );
        // console.log(createResponse)
    //   } catch (error) {
    //     console.log("error", error)
    //   }
      
      
    //   navigate("/payment"); // Redirige a la página de pago
    };
  
    return (
      <div
        style={{
          textAlign: "center",
          padding: "40px 20px",
          backgroundColor: "#f5f5f5",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "'Playfair Display', serif",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "30px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            maxWidth: "500px",
            width: "100%",
          }}
        >
          <h1 style={{ color: "#4b2e2e", marginBottom: "20px" }}>
            ¡Gracias por elegir {eleccion.title}!
          </h1>
          <p style={{ fontSize: "1.2em", marginBottom: "20px", color: "#555" }}>
            Monto :{eleccion.price}
          </p>
          <p style={{ fontSize: "1.2em", marginBottom: "20px", color: "#555" }}>
            Por favor, ingresa tu nombre para proceder con el pago.
          </p>
          <input
            type="text"
            placeholder="Tu nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              padding: "10px",
              fontSize: "16px",
              marginBottom: "20px",
              width: "100%",
              border: "1px solid #ddd",
              borderRadius: "5px",
            }}
          />
          <button
            onClick={handleProceed}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              backgroundColor: "#4b2e2e",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              width: "100%",
              transition: "background-color 0.3s ease",
            }}
          >
            Proceder al Pago
          </button>
        </div>

        {isModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "30px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              maxWidth: "400px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <h2 style={{ color: "#4b2e2e", marginBottom: "20px" }}>
              { !isTranfer && "Selecciona tu Método de Pago"}
            </h2>
            { isTranfer ? 
                <div className="bank-details">
                <h2 className="bank-title">Datos Bancarios</h2>
                <div className="bank-info">
                    <p><strong>Nombre:</strong> Gabriel Eduardo Sanhueza Díaz</p>
                    <p><strong>Rut:</strong> 18.781.022-1</p>
                    <p><strong>Banco:</strong> BCI</p>
                    <p><strong>Tipo de cuenta:</strong>  Corriente</p>
                    <p><strong>N° de cuenta:</strong> 89804546</p>
                    <p><strong>Correo:</strong> gabriel.sanhueza.d@gmail.com</p>
                    <p><strong>Monto:</strong> {eleccion.price}</p>

                </div>
              </div>
            :<div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                <div className="square" onClick={activandoTranfer}>
                    <MyIcon className="icon" />
                    <h3 className="title">Tranferencia Bancaria</h3>
                </div>
                <div className="square">
                    <img className="iconw" src="/webpay.jpg"/>
                    
                </div>
            </div>}
            <button
              onClick={handleCloseModal}
              style={{
                marginTop: "20px",
                padding: "5px 10px",
                fontSize: "12px",
                backgroundColor: "#ddd",
                color: "#333",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
      </div>
    );
  };



export default ConfirmPage;
