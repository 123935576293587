import React from "react";
import "../styles/InteractiveSquare.css";

const InteractiveSquare = ({ x, y, link }) => {
  return (
    <a
      href={link} // El enlace al que quieres ir
      target="_blank" // Abre el enlace en una nueva pestaña
      rel="noopener noreferrer" // Buenas prácticas de seguridad
      className="interactive-square"
      style={{
        left: x,
        top: y,
      }}
    >
      {/* Este cuadrado puede tener contenido adicional si lo deseas */}
    </a>
  );
};

export default InteractiveSquare;